.ProseMirror {
  padding: 10px;
  /*border-top: 1px solid grey;*/
  background: white;
  border-radius: 0 0 5px 5px;
  min-height: 450px;
}
/*.ProseMirror:focus {*/
/*border-top: none;*/
/*border: 1px solid #3E75D5;*/
/*}*/

.ProseMirror > * + * {
  margin-top: 0.75em;
}

.ProseMirror ul,
.ProseMirror ol {
  padding: 0 2rem;
}

.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3,
.ProseMirror h4,
.ProseMirror h5,
.ProseMirror h6 {
  line-height: 1.1;
}

.ProseMirror pre {
  background: #0d0d0d;
  color: #fff;
  font-family: 'JetBrainsMono', monospace;
  font-size: 12px;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}

.ProseMirror blockquote {
  padding-left: 1rem;
  border-left: 3px solid #999999;
}

.ProseMirror hr {
  border: none;
  border-top: 3px solid #999999;
  margin: 2rem 0;
}

.tiptap-editor {
  border-radius: 5px;
  border: 2px solid grey;
  border-top: none;
}
.tip-tap-toolbar {
  border: 2px solid grey;
  border-radius: 5px;
  /*border-bottom: none;*/
  /*padding-bottom: 5px;*/
  background: white;
  display: flex;
}
/*.tip-tap-toolbar button {*/
/*font-size: 18px;*/
/*margin: 7px;*/
/*margin-right: 15px;*/
/*outline: none;*/
/*border: none;*/
/*background: none;*/
/*color: rgb(70, 70, 70);*/
/*cursor: pointer;*/
/*}*/
.tip-tap-toolbar button {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 32px;

  margin: 0;
  padding: 0 8px;

  border: 0;
  border-radius: 4px;
  background: transparent;
  color: currentColor;

  font-family: inherit;
  font-size: inherit;
  line-height: 1;
  font-weight: inherit;

  white-space: nowrap;
  cursor: pointer;
}

.menu-button {
  width: 32px;
  padding: 0;
}

.button:hover,
.button.is-active,
.menu-button:hover,
.menu-button.is-active {
  background-color: var(--color-gray-5);
  color: var(--color-black);
}
.tip-tap-toolbar button:last-child {
  margin-right: 7px;
}
button.active {
  background: rgb(197, 197, 197);
  /*padding: 2px 3px;*/
  /*border-radius: 2px;*/
}
